<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-08 09:38:53
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 10:53:12
-->
<template>
  <div class="qj-register">
    <el-container>
      <!-- <el-header>
        <div class="header-body">
          <img
            class="headers-icon"
            src="../../assets/img/common/img-common-icon.png"
          />
          <div class="header-home" @click="$router.push('/login')">
            已有账号·前往登录
          </div>
        </div>
      </el-header> -->
      <el-main>
        <div class="register-body" v-if="page == 1">
          <div class="title">能匠到家注册信息填写</div>
          <div class="title-two">
            注册成为能匠城市合伙人后，您将可以接单和商品购买!
          </div>
          <el-form
            ref="registerForm"
            :model="registerForm"
            :rules="rules"
            size="mini"
          >
            <el-form-item prop="name">
              <span slot="label" style="color: red;line-height: 38px;">*</span>
              <el-input
                v-model="registerForm.name"
                placeholder="请输入用户名(必填)"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <span slot="label" style="color: red;line-height: 38px;">*</span>
              <el-input
                v-model="registerForm.phone"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <span slot="label" style="color: red;line-height: 38px;">*</span>
              <el-input v-model="registerForm.code" placeholder="请输入验证码">
                <el-button
                  :disabled="isDisabled"
                  slot="append"
                  @click="sendSms"
                  >{{ buttonText }}</el-button
                >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <span slot="label" style="color: red;line-height: 38px;">*</span>
              <el-input
                v-model="registerForm.password"
                show-password
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="verifyPwd">
              <span slot="label" style="color: red;line-height: 38px;">*</span>
              <el-input
                v-model="registerForm.verifyPwd"
                show-password
                placeholder="请输入确认密码"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="address">
              <el-input
                v-model="registerForm.address"
                placeholder="填写工作区域（具体详细地址）"
              ></el-input>
            </el-form-item> -->
            <el-form-item prop="address">
              <span slot="label" style="color: red;line-height: 38px;">*</span>
              <!-- <el-cascader
                v-model="registerForm.address"
                placeholder="填写工作区域"
                filterable
                :options="getAddress"
                :style="{width: '100%'}"
              ></el-cascader> -->
              <el-cascader
                :append-to-body="false"
                placeholder="填写工作区域"
                filterable
                v-model="registerForm.address"
                :options="getAddress"
                popper-class="address-popper-class"
                :style="{ width: '100%' }"
              ></el-cascader>
            </el-form-item>
            <el-form-item prop="inviteCode">
              <span slot="label" style="line-height: 38px;"> &nbsp; </span>
              <el-input
                v-model="registerForm.inviteCode"
                placeholder="邀请码(非必填)"
              ></el-input>
            </el-form-item>
            <el-form-item prop="remarke">
              <span slot="label" style="line-height: 38px;"> &nbsp; </span>
              <el-input
                type="textarea"
                class="form-textarea"
                v-model="registerForm.remarke"
                placeholder="填写备注信息(非必填)"
                :rows="5"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label" style="line-height: 38px;"> &nbsp; </span>
              <el-button type="primary" @click="submitForm">立即注册</el-button>
            </el-form-item>
          </el-form>
          <div class="desc">
            信息提交完成后我们将对您所提交的信息进行审核，审核后我们将对您发送审核信息，保持手机畅通。
          </div>
        </div>
        <Service v-if="page == 2" @nextserve="nextserve"></Service>
        <Play v-if="page == 3" :margin="margin" @nextplay="nextplay"></Play>
        <Status v-if="page == 4"></Status>
      </el-main>

      <!--  弹框成为在线合伙人 -->
      <!-- <el-dialog
        v-show="(page = 1)"
        class="clause"
        :visible.sync="dialogVisible"
        width="57%"
      >
        <div class=" title">能匠到家城市合伙人服务条款</div>
        <div class="remind">
          请先联系客服了解详细信息，了解后点击下一步进行缴纳保证金
        </div>
        <div class="content">
          <p>欢迎您访问能匠到家网！</p>
          <div></div>
        </div>
        <div class="choice">
          <el-radio v-model="radio" label="1" @click="cut"
            >勾选同意以下服务条款</el-radio
          >
        </div>
        <html></html>
        <span slot="footer" class="dialog-footer">
          <el-button class="btn" @click="goage">下一步</el-button>
        </span>
      </el-dialog> -->

      <!--服务条款 -->
      <!-- <el-dialog
        v-show="(page = 2)"
        class="clause"
        :visible.sync="dialogVisible"
        width="57%"
      >
        <div class=" title">能匠到家城市合伙人保证金缴纳</div>
        <div class="remind">
          注册成为能匠城市合伙人后，您将可以接单和商品购买!
        </div>
        <div class="middle">
          <div class="middle_sum">
            <span
              >共计支付<span>￥</span
              ><span class="middle_amount">1000</span></span
            >
          </div> -->
      <!-- <div class="middle_code">
            <img src="../../assets/img/home/img-wx-code.png" alt="" />
          </div>
        </div>
        <div class="choice"></div>
        <html></html>
        <span slot="footer" class="dialog-footer">
          <el-button class="btn" @click="goage">下一步</el-button>
          <div class="middle_hint">
            信息提交完成后我们将对您所提交的信息进行审核，审核后我们将对您发送审核信息，保持手机畅通。
          </div>
        </span>
      </el-dialog>  -->

      <!-- 注册审核 -->
      <!-- <el-dialog
        v-show="(page = 3)"
        class="clause"
        :visible.sync="dialogVisible"
        width="57%"
      >
        <div class=" title">城市合伙人审核</div>
        <div class="remind">
          缴纳保证金后，我们将为您很合，审核通过后将成为城市合伙人!
        </div>
        <div class="middle">
          <div class="middle_code">
            <img src="../../assets/img/home/img-sou.png" alt="" />
            <div class="middle_subtitle">审核中</div>
            <div class="middle_check">当前正在审核中</div>
            <div class="middle_check">审核预计需要1~2天，请耐心等待</div>
          </div>
        </div>
        <div class="choice"></div>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog> -->

      <!-- 审核成功 -->
      <!-- <el-dialog
        v-show="(page == 4)"
        class="clause"
        :visible.sync="dialogVisible"
        width="57%"
      >
        <div class=" title">城市合伙人审核</div>
        <div class="remind">
          缴纳保证金后，我们将为您很合，审核通过后将成为城市合伙人!
        </div>
        <div class="middle">
          <div class="middle_code">
            <img src="../../assets/img/home/img-good.png" alt="" />
            <div class="middle_subtitle">审核成功</div>
          </div>
        </div>
        <div class="choice"></div>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog> -->

      <!-- 审核失败 -->
      <!-- <el-dialog
        v-show="(page == 5)"
        class="clause"
        :visible.sync="dialogVisible"
        width="57%"
      >
        <div class=" title">城市合伙人审核</div>
        <div class="remind">
          缴纳保证金后，我们将为您很合，审核通过后将成为城市合伙人!
        </div>
        <div class="middle">
          <div class="middle_code">
            <img src="../../assets/img/home/img-lose.png" alt="" />
            <div class="middle_subtitle">审核失败</div>
            <div>失败原因是因为您不符合城市合伙人要求~</div>
          </div>
        </div>
        <div class="choice"></div>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog> -->
    </el-container>
    <div
      class="service"
      :class="{ 'is-pack-up': !isPackUp }"
      id="source"
      draggable="true"
      v-show="chat"
    >
      <div class="head" @click.stop="close">
        <span class="headset"></span>
        <span class="slogan">能匠到家在线客服</span>
        <i class="el-icon-close" v-show="isPackUp" @click.stop="close"></i>
      </div>
      <div class="dialogue" v-show="isPackUp">
        <div class="dialogue_date">{{$utils.dateFormat(new Date(), 'mm月dd日 hh:mm')}}</div>
        <div class="dialogue_remind">
          <div class="left"></div>
          <div class="right">
            <div class="writing">能匠到家小助提示</div>
            <div class="writing">
              <span>客服热线：</span>
              <span style="color: #0075ff">{{ contactPhone }}</span>
            </div>
            <div class="writing">
              <span>QQ咨询：</span>
              <a
                style="color: #0075ff; cursor: pointer; text-decoration: none"
                :href="`tencent://message/?uin=${kfQQ}&Site=Sambow&Menu=yes`"
                >在线咨询</a
              >
              <!-- <span>点击咨询</span> -->
            </div>
            <div class="codes">
              <img src="@/assets/img/home/img-code.png" alt="" />
            </div>
          </div>
        </div>
        <div class="response">
          <div class="left">您好，我是能匠到家小助，请问您有什么需要帮助？</div>
          <div class="right"></div>
        </div>
        <div class="enter">
          <el-input
            v-model="input"
            placeholder="请输入问题，我们将尽快回复"
          ></el-input>
          <a :href="`tencent://message/?uin=${kfQQ}&Site=Sambow&Menu=yes`">
            <el-button type="primary" size="small"> 发送 </el-button>
          </a>
        </div>
      </div>
    </div>
    <el-dialog
      close="my_dialog"
      top="35vh"
      :visible.sync="successPop"
      width="477px"
      title="恭喜您，注册成功！"
    >
      <img
        src="@/assets/img/login/img-ren.png"
        style="display: block; margin: 0 auto"
        alt=""
      />
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" style="margin-right: 80px" @click="join()">
          城市合伙人
        </el-button>
        <el-button type="primary" plain @click="$router.push('/home')">
          继续浏览
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Service from "./children/service/index.vue";
import Play from "./children/play/index.vue";
import Status from "./children/status/index.vue";
import { session } from "@/utils/sessions";
export default {
  components: {
    Service,
    Play,
    Status
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号码不可为空"));
      } else {
        if (this.checkMobile(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var checkCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码不可为空"));
      } else if (value.toString().length < 6) {
        callback(new Error("验证码最少6位数"));
      } else {
        callback();
      }
    };
    var checkPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不可为空"));
      } else if (value.toString().length < 6) {
        callback(new Error("密码最少6位数"));
      } else {
        callback();
      }
    };
    var checkVerifyPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("确认密码不可为空"));
      } else if (value !== this.registerForm.password) {
        callback(new Error("确认密码和密码不相同!"));
      } else {
        callback();
      }
    };
    return {
      kfQQ: "",
      contactPhone: "",
      registerForm: {
        name: "",
        phone: "", //手机号
        code: "", // 验证码
        password: "", // 密码
        verifyPwd: "", // 确认密码
        address: undefined, // 地址
        inviteCode: "", // 邀请码
        remarke: "" // 备注
      },
      // 在线客服显示
      chat: false,
      isPackUp: true,
      margin: 0,
      input: "",
      // 弹框
      dialogVisible: true,
      // 下一页
      page: 1,
      radio: false,
      buttonText: "获取验证码", // 验证码文本
      isDisabled: false, // 是否禁止点击发送验证码按钮
      flag: true,
      rules: {
        name: [{ required: true, message: "用户名不可为空", trigger: "blur" }],
        phone: [{ validator: checkPhone, trigger: "blur" }],
        code: [{ validator: checkCode, trigger: "blur" }],
        password: [{ validator: checkPass, trigger: "blur" }],
        verifyPwd: [{ validator: checkVerifyPwd, trigger: "blur" }],
        address: [
          { required: true, message: "请选择工作区域", trigger: "blur" }
        ]
      },
      successPop: false
    };
  },

  created() {
    if (this.$route.query.type == 1) {
      this.page = 2;
      this.chat = true;
    }
  },
  computed: {
    getAddress() {
      return this.$store.getters.address || [];
    }
  },
  mounted() {
    this.getIntroduce();
    // window.onload = function() {
    //   var drag = document.getElementById("source");
    //   drag.onmousedown = function(event) {
    //     event = event || window.event; //兼容IE浏览器
    //     //    鼠标点击物体那一刻相对于物体左侧边框的距离=点击时的位置相对于浏览器最左边的距离-物体左边框相对于浏览器最左边的距离
    //     var diffX = event.clientX - drag.offsetLeft;
    //     var diffY = event.clientY - drag.offsetTop;
    //     if (typeof drag.setCapture !== "undefined") {
    //       drag.setCapture();
    //     }
    //   };
    //   document.onmousemove = function(event) {
    //     event = event || window.event;
    //     var moveX = event.clientX - diffX;
    //     var moveY = event.clientY - diffY;
    //     if (moveX < 0) {
    //       moveX = 0;
    //     } else if (moveX > window.innerWidth - drag.offsetWidth) {
    //       moveX = window.innerWidth - drag.offsetWidth;
    //     }
    //     if (moveY < 0) {
    //       moveY = 0;
    //     } else if (moveY > window.innerHeight - drag.offsetHeight) {
    //       moveY = window.innerHeight - drag.offsetHeight;
    //     }
    //     drag.style.left = moveX + "px";
    //     drag.style.top = moveY + "px";
    //   };
    //   document.onmouseup = function(event) {
    //     this.onmousemove = null;
    //     this.onmouseup = null;
    //     //修复低版本ie bug
    //     if (typeof drag.releaseCapture != "undefined") {
    //       drag.releaseCapture();
    //     }
    //   };
    // };
  },
  methods: {
    submitForm() {
      // this.successPop = true;
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "注册中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let params = { ...this.registerForm };
          let addressArr = [...this.getAddress];
          let area = [...this.registerForm.address];
          let addressStr = "";
          let i = 0;
          area.map(item => {
            let addressJson = addressArr.find(nape => {
              return nape.value == item;
            });
            if (addressJson) {
              if (i > 0) {
                addressStr += ",";
              }
              addressStr += addressJson.label;
              i++;
              if (addressJson.children) {
                addressArr = [...addressJson.children];
              }
            }
          });
          params.address = addressStr;
          console.log("params", params);
          this.$api
            .registered(params)
            .then(() => {
              this.$message({
                message: "恭喜您，注册成功！",
                type: "success"
              });
              this.successPop = true;
              this.chat = true;
              this.$api
                .login({
                  phone: this.registerForm.phone,
                  password: this.registerForm.password
                })
                .then(res => {
                  session.setLoginToken(res.data.token);
                  this.$api.getUserInfo({ id: res.data.id }).then(user => {
                    session.setUsers(user.data);
                    this.$store.dispatch("getUser");
                    // this.page = 2;
                    // let path = session.getFullPath();
                    // if (path) {
                    //   // session.removeFullPath();
                    //   // this.$router.push(path);
                    // }
                  });
                });

              // this.$router.push("/login");
            })
            .catch(msg => {
              this.$message.error(msg?.msg);
            })
            .finally(() => {
              loading.close();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    join() {
      this.$api
        .login({
          phone: this.registerForm.phone,
          password: this.registerForm.password
        })
        .then(res => {
          session.setLoginToken(res.data.token);
          this.$api.getUserInfo({ id: res.data.id }).then(user => {
            session.setUsers(user.data);
            this.$store.dispatch("getUser");
            this.page = 2;
            this.successPop = false;
            let path = session.getFullPath();
            if (path) {
              session.removeFullPath();
              // this.$router.push(path);
            }
          });
        });
    },
    getIntroduce() {
      this.$api.getIntroduce().then(res => {
        if (res.data && res.data.qqService) {
          this.kfQQ = res.data.qqService;
        }
        if (res.data && res.data.contactPhone) {
          this.contactPhone = res.data.contactPhone;
        }
        // data.
      });
    },
    //  原版本注册页面
    // submitForm() {
    //   this.$refs.registerForm.validate(valid => {
    //     if (valid) {
    //       const loading = this.$loading({
    //         lock: true,
    //         text: "注册中...",
    //         spinner: "el-icon-loading",
    //         background: "rgba(0, 0, 0, 0.7)"
    //       });
    //       let params = { ...this.registerForm };
    //       let addressArr = [...this.getAddress];
    //       let area = [...this.registerForm.address];
    //       let addressStr = "";
    //       let i = 0;
    //       area.map(item => {
    //         let addressJson = addressArr.find(nape => {
    //           return nape.value == item;
    //         });
    //         if (addressJson) {
    //           if (i > 0) {
    //             addressStr += ",";
    //           }
    //           addressStr += addressJson.label;
    //           i++;
    //           if (addressJson.children) {
    //             addressArr = [...addressJson.children];
    //           }
    //         }
    //       });
    //       params.address = addressStr;
    //       console.log(addressStr, params);
    //       this.$api
    //         .registered(params)
    //         .then(() => {
    //           this.$message({
    //             message: "恭喜您，注册成功！",
    //             type: "success"
    //           });
    //           this.$router.push("/login");
    //         })
    //         .catch(msg => {
    //           this.$message.error(msg?.msg);
    //         })
    //         .finally(() => {
    //           loading.close();
    //         });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    // 发送验证码
    sendSms() {
      let phone = this.registerForm.phone;
      if (this.checkMobile(phone)) {
        const loading = this.$loading({
          lock: true,
          text: "获取验证码中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        let self = this;
        let params = { phone:phone, status: 2 };
        this.$api
          .sendSms(params)
          .then(() => {
            let time = 60;
            self.buttonText = "已发送";
            self.isDisabled = true;
            if (self.flag) {
              self.flag = false;
              let timer = setInterval(() => {
                time--;
                self.buttonText = time + " 秒";
                if (time === 0) {
                  clearInterval(timer);
                  self.buttonText = "重新获取";
                  self.isDisabled = false;
                  self.flag = true;
                }
              }, 1000);
            }
          })
          .catch(msg => {
            this.$message.error(msg?.msg);
          })
          .finally(() => {
            loading.close();
          });
      } else {
        this.$refs.registerForm.validateField("phone");
      }
    },
    // 验证手机号
    checkMobile(str) {
      let reg = /^1[3-9][0-9]\d{8}$/;
      if (reg.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    nextserve(val) {
      this.page = 3;
      this.margin = val;
    },
    nextplay() {
      this.page = 4;
    },
    close() {
      this.isPackUp = !this.isPackUp;
    }

    // goage(){
    //     this.page = this.page++
    //           console.log(this.page);
    // }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
