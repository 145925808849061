<template>
  <div class="qj-register">
    <el-container>
      <el-main>
        <div class="register-body">
          <div class="clause">
            <div class=" title">城市合伙人审核</div>
            <div class="remind">
              缴纳保证金后，我们将为您审核，审核通过后将成为城市合伙人!
            </div>
            <div class="middle">
              <!-- 审核中 -->
              <div class="middle_code" v-if="status == 1">
                <img src="@/assets/img/home/img-sou.png" alt="" />
                <div class="middle_subtitle">审核中</div>
                <div class="middle_check">当前正在审核中</div>
                <div class="middle_check">审核预计需要1~2天，请耐心等待</div>
                <div>
                  <el-button type="primary" @click="$router.push('/')" size="small">返回首页</el-button>
                  <!-- <button @click="goBack">返回首页</button> -->
                </div>
              </div>
              <!-- 审核成功 -->
              <div class="middle" v-else-if="status == 2">
                <div class="middle_code">
                  <img src="@/assets/img/home/img-good.png" alt="" />
                  <div class="middle_subtitle">审核成功</div>
                </div>
              </div>
              <!-- 审核失败 -->
              <div v-else-if="status == 3">
                <div class="middle">
                  <div class="middle_code">
                    <img src="@/assets/img/home/img-lose.png" alt="" />
                    <div class="middle_subtitle">审核失败</div>
                    <div style="font-size:14px;">
                      失败原因是因为您不符合城市合伙人要求~
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <el-button class="btn" @click="next">下一步</el-button> -->
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      status: 1
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    goBack() {
      this.$router.push("/");
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
@import "../../index.less";
</style>
