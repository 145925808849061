<template>
  <div class="qj-register">
    <el-container>
      <el-main>
        <div class="register-body">
          <div class="clause">
            <div class="title">能匠到家城市合伙人保证金缴纳</div>
            <div class="remind">
              注册成为能匠城市合伙人后，您将可以接单和商品购买!
            </div>
            <div class="middle">
              <div class="middle_sum">
                <span
                  >共计支付<span>￥</span
                  ><span class="middle_amount">{{ margin }}</span></span
                >
              </div>

              <div class="middle_code">
                <!-- <div id="qrcode" ref="qrcode"></div> -->
                <!-- <img :src="data.bate" alt="" /> -->
                <!--  -->
                <img
                  width="200"
                  height="200"
                  style="	border: solid 1px #e5e5e5; border-radius: 10px;"
                  :src="imgUrl"
                  alt=""
                />
              </div>
            </div>
            <div class="scan">请用【微信】扫码付款</div>
            <html></html>

            <!-- <el-button class="btn" @click="next">下一步</el-button> -->
            <div class="middle_hint">
              信息提交完成后我们将对您所提交的信息进行审核，审核后我们将对您发送审核信息，保持手机畅通。
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  props: ["margin"],
  data() {
    return {
      websock: null,
      state: "",
      paySuccess: false,
      imgUrl: ""
    };
  },
  computed: {},
  created() {
    this.query_url();
    // this.$nextTick(() => {
    //   // 禁用右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    //   //禁止f12
    //   document.οnkeydοwn = new Function("event.returnValue=false");
    // });
  },
  mounted() {
    this.connectSocket();
  },
  watch: {},
  methods: {
    next() {
      if (this.paySuccess) {
        this.$emit("nextplay");
        this.websock.onclose();
      } else {
        this.$message({
          type: "error",
          message: "请您完成支付!",
          druation: 2000
        });
      }
    },
    query_url() {
      this.$api
        .wxPay({
          // money: this.margin,
          money: this.margin, // 测试金额
          userId: this.$store.getters.userId
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.imgUrl = "data:image/png;base64," + data.bate;
          }
        });
    },

    connectSocket() {
      // let timestamp = new Date().valueOf();
      // var num = Math.floor(Math.random() * 10000 + 1);
      // this.state = timestamp.toString() + num.toString();
      // let url = `wss://www.liankexue.cn/linkscience/webSocket/${this.state}`;
      let url = `wss://www.nengjiangdaojia.com/water_purification/websocket`;

      // 创建websocket连接
      this.websock = new WebSocket(url);
      this.websock.onopen = this.websockOpen; // 监听打开
      this.websock.onclose = this.websockClose; // 监听关闭
      this.websock.onerror = this.websockError; //监听异常
      this.websock.onmessage = this.websockMessage; //监听服务器发送的消息
    },

    websockOpen() {
      console.log("监听打开");
      //   this.websock.send("------")
    },
    websockClose() {
      console.log("监听关闭");
    },
    websockError() {
      console.log("监听异常");
    },
    websockMessage(e) {
      let result = JSON.parse(e.data);
      console.log("监听服务器发送的消息", result);

      if (result.code == 1) {
        this.paySuccess = true;
        this.websock.onclose();
        this.next();
      } else if (result.code == 0) {
        this.websock.onclose();
      }
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
@import "../../index.less";
@import "./index.less";
</style>
