<template>
  <div class="qj-register">
    <el-container>
      <el-main>
        <div class="register-body">
          <div class="clause">
            <div class="title">能匠到家城市合伙人服务条款</div>
            <div class="remind">
              请先联系客服了解详细信息，了解后点击下一步进行缴纳保证金
            </div>
            <div class="content">
              <p class="content_title">欢迎您访问能匠到家网！</p>
              <div class="describe" v-html="infoData.signingProcess"></div>
            </div>
            <div class="signature">
              <span class="sign">签名:</span>
              <span v-if="imgUrl.length > 0">
                <img
                  class="beginImg"
                  width="60"
                  height="40"
                  :src="imgUrl"
                  alt=""
                />
                <!-- <span class="begin" @click="open()">点击鼠标重新签名</span> -->
              </span>
              
              <span class="begin" @click="open()" v-else>点击鼠标签名</span>
            </div>
            <div style="padding-left: 110px"></div>
            <div class="choice">
              <el-radio v-model="radio" label="1"
                >勾选同意以下服务条款</el-radio
              >
            </div>

            <el-button class="btn" @click="next">下一步</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="客户签名" :visible.sync="dialogVisible" width="900">
      <p class="dialog_title">鼠标手写签名</p>
      <sign-canvas
        class="sign-canvas"
        ref="SignCanvas"
        :options="options"
        v-model="value"
      />
      <div class="btnList">
        <el-button type="primary" size="small" @click="saveAsImg()"
          >生成签字</el-button
        >

        <el-button type="" size="small" @click="canvasClear()"
          >重新签字</el-button
        >
      </div>
      <!-- <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>
    <!-- 
    <div class="service">
      <div class="top">
        <span>1</span>
        <span>2</span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      infoData: {},
      radio: "",
      dialogVisible: false,
      signature: false,
      imgUrl: "",
      options: {
        lastWriteSpeed: 1, //书写速度 [Number] 可选
        lastWriteWidth: 2, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
        lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 788, //canvas宽高 [Number] 可选
        canvasHeight: 327, //高度  [Number] 可选
        isShowBorder: true, //是否显示边框 [可选]
        bgColor: "#fff", //背景色 [String] 可选
        // borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: "#dcdcdc", //网格颜色  [String] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: "#101010", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
      value: "",
    };
  },

  computed: {},
  created() {
    this.query_getSigningProcess();
    if (localStorage.getItem("imgUrl")) {
      this.imgUrl = localStorage.getItem("imgUrl");
    }
  },
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.canvasClear();
      }
    },
  },
  methods: {
    query_getSigningProcess() {
      this.$api.getSigningProcess().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.infoData = data;
        }
      });
    },
    open() {
      this.dialogVisible = true;
    },
    next() {
      if (this.radio) {
        // if (!this.signature) {
        if (this.imgUrl.length == 0) {
          this.$message({
            type: "error",
            message: "请您签字!",
            druation: 2000,
          });
        } else {
          this.$emit("nextserve", this.infoData.margin);
          localStorage.removeItem("imgUrl");
        }
        // this.dialogVisible = true;
        //
      } else {
        this.$message({
          type: "error",
          message: "请您勾选同意服务条款!",
          druation: 2000,
        });
      }
    },
    /**
     * 清除画板
     */
    canvasClear() {
      this.$refs.SignCanvas.canvasClear();
      // this.$refs.SignCanvas.reset();
    },

    /**
     * 生成签字
     */
    // saveAsImg() {
    //   const img = this.$refs.SignCanvas.saveAsImg();
    //   console.log(img);
    //   this.$api
    //     .signing({
    //       file: img,
    //       userId: this.$store.getters.userId
    //     })
    //     .then(res => {
    //       let { code, msg } = res;
    //       if (code == 200) {
    //         this.dialogVisible = false;
    //         this.signature = true;
    //         this.$refs.SignCanvas.canvasClear();
    //         // this.$emit("nextserve", this.infoData.margin);
    //         this.$message({
    //           type: "success",
    //           message: msg,
    //           duration: 2000
    //         });
    //       }
    //     });
    //   // alert(`image 的base64：${img}`);
    //   // this.$emit("update:visible", false);
    // }
    /**
     * 生成签字
     */
    saveAsImg() {
      if (this.value) {
        const img = this.$refs.SignCanvas.saveAsImg();
        this.$api
          .signing({
            file: img,
            userId: this.$store.getters.userId,
          })
          .then(res => {
            let { code, msg } = res;
            if (code == 200) {
              this.imgUrl = img;
              localStorage.setItem("imgUrl", img);
              this.dialogVisible = false;
              this.signature = true;
              this.$refs.SignCanvas.canvasClear(); // this.$emit("nextserve", this.infoData.margin);
              this.$message({
                type: "success",
                message: msg,
                duration: 2000,
              });
            }
          });
      } else {
        this.$message({
          type: "error",
          message: "请您签字",
          duration: 2000,
        });
      } //alert(`image 的base64：${img}`); // this.$emit("update:visible", false);
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
@import "../../index.less";
@import "./index.less";
</style>
